import React from 'react'
import '../../App.css'
import './About.css'

function About() {
  return (
    <div className = 'about'>
        
        <div className = 'wrapper'>
            <h1>About</h1>

            <div className = 'summary-container'>
                <h2>Summary</h2>
                <p>
                    Hello, there. You are a curious one to reach the about page.
                </p>

                <p>
                    My name is Nilesh Bhatia. I'm a freelance web designer. You might be thinking about what a freelancer is. A freelancer is someone who works independently. Basically, I'm self-employed. I build fascinating websites for intriguing people. I help people to establish their online presence. How? I create websites that make sure your business/brand stands out among the hundred. In this digital era, one must have to digitalize their business/brand. And if you want to grow your business/brand and outshine your competitors, you need a website. If you aren't serious about your business/brand and living under the rock, you probably don't need a website.
                </p>

                <p>
                    If you are looking for a website or have general queries about a website, don't hesitate to reach out. Contact me <a href = '/contact'>here</a>.
                </p>
            </div>

            <div className = 'prolonged-container'>
                <h2>Prolonged</h2>
                <h3>Opening Remarks</h3>
                <p>
                    My name is Nilesh Bhatia. I belong to a beautiful state known as Himachal Pradesh (India). I completed my education at the School of Hard Knocks. I'm a Freelance Web Designer. More precisely, I'm a Freelance Front-End Web Developer. I'm also an amateur writer who has a keen interest in poetry and philosophy. 
                </p>

                <h3>Odyssey</h3>
                <p>
                    My journey started with the quest for knowledge at the beginning of my youth. On the boulevard of not so broken dreams, I faced many difficulties. I didn't stop. There were so many distractions that strayed me from my quest. I kept crawling through ashes and smoke. One day I said to myself, let me sleep into these distractions. And wake me up when distractions end. And that's what I did. I woke up breaking the old habits and as a more determined person. I left all those vices behind. But the journey was more difficult than before as I reached one step closer to the quest. And you know, the more you are close, the harder it is. Now the quench for the treasure added with the knowledge. I met a demon disguised as a Demigod who promised me treasure and gave me many boring websites to work on. I thought this is my end, but the treasure was my motivation. I was about to quit. Soon enough, I realized that he was a demon all way long and declared war with him. But in the end, I killed him with his spear and emerged as a victorious person. Sadly, there was no treasure. Now I'm atoning for my sins by creating beautiful and creative websites. 
                </p>

                <h3>How Can I Help You?</h3>
                <p>
                    There are many ways I can help you. Let me state a few. I can help you to create your website. I can babysit your websites as they demand care as a child. I can help you to turn your name into a brand. I can help you to stand out from your competitors in your market. I can help with your low conversion rate of the website. I can perform an audit on why your website is not giving the result you wish. I can help you migrate your WordPress website. I can help you to make pizza at home. Just tell me what you are looking for. 
                </p>

                <h3>Whom do I work with? </h3>
                <p>
                    I love to work with Writers, Artists/Digital Artists, Content Creators, Social Media Influencers, YouTubers, Photographers, Small to Mid Scale businesses, basically everyone who belongs to a creative field. Moreover, we can work along if you have something interesting to work on. I have a never-ending appetite for interesting projects. 
                </p>

                <h3>
                    What Else 
                </h3>
                <p>
                    Apart from Web development, I have a few hobbies. I can't sit all day facing laptop writing lines of code. I play Guitar. Not good enough to impress people but good enough to play 'Stairway to Heaven.' You may have heard about speedcubers. I'm a slow speedcuber. I love to read books without caring about the genre. I love to play Chess. Above them all is, writing. I love to write. I write anything from rants to poetry. But most of the pieces don't come out from the draft. 
                </p>

                <h3>Out of the context paragraph</h3>
                <p>
                    I have a habit of sharing beautiful music. So I have curated a Spotify playlist composed most of the Hindi songs. If you are a music buff and want to go on a ride of different emotions, try my playlist. You will forget Arijit. *wink*
                </p>
                <p>
                    Here is the link to the playlist - <a href = 'https://open.spotify.com/playlist/2W3RK2lQgyb1ZHVncDwZ1b' target = '_blank'>Beautiful Boulevard</a>. Do write to me if this playlist made a place in your heart. (which is very unlikely to happen)
                </p>

                <p>
                    Thank you for reading this page entirely. You are one of a kind. For your efforts, I will give you a discount on your project. *Not Joking* *God Promise* 
                </p>

            </div>
        </div>
    </div>
  )
}

export default About