import React from 'react'
import { Link } from 'react-router-dom'
import './PortfolioSection.css'
import MajorNeelPic from '../images/Major-Neel.webp'
import Portfolio from './pages/Portfolio'

function PortfolioSection() {
  return (
    <div className = 'portfolio-section'>
        <div className = 'wrapper'>
            <h2>
                See my work
            </h2>

            <div className = 'container'>
                <img src = {MajorNeelPic} />
                <div className = 'text-block'>
                    <p>The Skin Doctor</p>
                    <p><a href = 'https://www.majorneel.in' target = '_blank'>Visit website</a></p>
                </div>
            </div>

            <a href = 'About'><button>View more</button></a>
        </div>
    </div>
  )
}

export default PortfolioSection