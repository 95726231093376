import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home'
import About from './components/pages/About'
import Portfolio from './components/pages/Portfolio'
import Contact from './components/pages/Contact'

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path = '/' exact element = {<Home />}/>
          <Route path = '/about' exact element = {<About />}/>
          <Route path = '/portfolio' exact element = {<Portfolio />}/>
          <Route path = '/contact' exact element = {<Contact />}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
