import React from 'react'
import '../../App.css'
import HeroSection from '../HeroSection.js'
import AboutSection from '../AboutSection.js'
import ImakeWebsiteSection from '../ImakeWebsiteSection.js'
import PortfolioSection from '../PortfolioSection.js'

function Home () {
    return (
        <>
            <HeroSection />
            <AboutSection />
            <ImakeWebsiteSection />
            <PortfolioSection />
        </>
    )
}

export default Home;