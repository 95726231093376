import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'
import About from './pages/About'
import Portfolio from './pages/Portfolio'
import Contact from './pages/Contact'

function Footer() {
  return (
    <div className = 'footer'>
        <div className = 'wrapper'>
            <h2>Contact <a href = 'Contact'>here</a> for web design services or maybe a few drinks.</h2>
            <ul class = 'footer-nav'>
                <li className = 'footer-nav-item'>
                    <Link to = '/' className = 'footer-nav-links' tabIndex = '-1'>Home</Link>
                </li>
                <li className = 'footer-nav-item'>
                    <Link to = '/About' className = 'footer-nav-links' tabIndex = '-1'>About</Link>
                </li>
                <li className = 'footer-nav-item'>
                    <Link to = '/Portfolio' className = 'footer-nav-links' tabIndex = '-1'>Portfolio</Link>
                </li>
                <li className = 'footer-nav-item'>
                    <Link to = '/Contact' className = 'footer-nav-links' tabIndex = '-1'>Contact</Link>
                </li>
            </ul>

            <hr class = 'footer-hr' />
            <div class = 'footer-text-block'>
                <p>Powered by Midas Touch</p>
                <p>© 2023 Nilesh</p>
            </div>
        </div>
    </div>
  )
}

export default Footer