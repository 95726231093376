import React from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import './HeroSection.css'
import Arrow from '../images/Nilesh-Bhatia-arrow.svg'
import HeroImg from '../images/Nilesh-Bhatia-hero-img.webp'
import Portfolio from './pages/Portfolio'

function HeroSection() {
  return (
    <div className = 'hero'>
        <div className = 'wrapper'>
            <div className = 'text-container'>
                <p>Creating websites and growing businesses</p>
                <h1>Let's give <span className = 'blue'>Midas Touch</span> to your business!</h1>
                <div className = 'portfolio-btn'>
                    <p><Link to = '/Portfolio'>Portfolio</Link></p>
                    <img src = {Arrow} />
                </div>
            </div>

            <div className = 'hero-img'>
                <img src = {HeroImg} />
            </div>

        </div>
    </div>
  )
}

export default HeroSection