import React, { useRef } from 'react'
import emailjs from 'emailjs-com'
import '../../App.css'
import './Contact.css'

function Contact() {
    const form = useRef();
    
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_nbin', 'template_yc7g3c9', form.current, 'xCSl13GVpGPfJjMjC')
        .then((result) => {
            alert('Hey! Thankyou for contacting me. I will get back to you soon.');
        }, (error) => {
            alert('Something is wrong. Will you please try again :)');
        });
        e.target.reset()
    }
    
    return (
        <div className = 'contact'>
            <div className = 'wrapper'>
                <h1>Contact</h1>
                <p>Fill out the form, and I will get back to you in the blink of an eye.</p>
                <hr />
                <div className = 'container'>
                    <div className = 'form-container'>
                        <form onSubmit = {sendEmail} ref = {form} id = 'contactform'>
                            <div>
                                <label>
                                    Your name <br />
                                    <input type = 'text' name = 'name' className = 'input-area' required/>
                                </label>
                            </div>

                            <div>
                                <label>
                                    Your email <br />
                                    <input type = 'email' name = 'email' className = 'input-area' required/>
                                </label>
                            </div>

                            <div>
                                <label>
                                    Your (whatsapp) number <br />
                                    <input type = 'tel' name = 'number' className = 'input-area' required/>
                                </label>
                            </div>

                            <div>
                                <label>
                                    Communication preference <br />
                                    <select form = 'contactform' name = 'commPre' className = 'input-select' required>
                                        <option value = 'WhatsApp'>WhatsApp</option>
                                        <option value = 'Call'>Call</option>
                                        <option value = 'Email'>Email</option>
                                        <option value = 'Telepathy'>Telepathy</option>
                                    </select>
                                </label>
                            </div>
                            
                            <div>
                                <label>
                                    Reason to contact <br />
                                    <select form = 'contactform' name = 'rtc' className = 'input-select' required>
                                        <option value = 'Web design services'>for Web design services</option>
                                        <option value = 'General queries'>for General queries</option>
                                        <option value = 'For drinks'>for Drinks</option>
                                        <option value = 'to talk'>I need someone to talk</option>
                                    </select>
                                </label>
                            </div>
                            
                            <div>
                                <label>
                                    How did you find me? <br />
                                    <select form = 'contactform' name = 'how' className = 'input-select' required>
                                        <option value = 'facebook'>through Facebook</option>
                                        <option value = 'instagram'>through Instagram</option>
                                        <option value = 'linkedin'>through LinkedIn</option>
                                        <option value = 'google'>through Google</option>
                                        <option value = 'other'>Other</option>
                                    </select>
                                </label>
                            </div>

                            <div>
                                <label>
                                    Your message <br />
                                    <textarea name = 'msg' className = 'input-area input-msg' required></textarea>
                                </label>
                            </div>

                            <div>
                                <input type = 'submit' name = 'submit' value = 'Send' className = 'send-btn'/>
                            </div>
                        </form>
                    </div>

                    <div className = 'sidebar'>
                        <div className = 'sidebar-1'>
                            <h3>More info</h3>
                            <div className = 'line'></div>
                        </div>

                        <div className = 'sidebar-2'>
                            <p>Email:</p>
                            <p>namaste@nileshbhatia.in</p>
                        </div>

                        <div className = 'sidebar-3'>
                            <p>Address:</p>
                            <p>Currently meditating somewhere in Himalayas</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact