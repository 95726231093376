import React from 'react'
import './ImakeWebsiteSection.css'

import businesssvg from '../images/Nilesh-Bhatia-business.svg'
import artistsvg from '../images/Nilesh-Bhatia-artist.svg'
import photographersvg from '../images/Nilesh-Bhatia-photographer.svg'
import ccsvg from '../images/Nilesh-Bhatia-cc.svg'
import ytsvg from '../images/Nilesh-Bhatia-yt.svg'
import cpsvg from '../images/Nilesh-Bhatia-cp.svg'

function ImakeWebsiteSection() {
  return (
    <div className = 'ImakeWebsiteSection'>
        <div className = 'wrapper'>
            <h2>I design websites for</h2>

            <div className = 'card-container'>
                <div className = 'card'>
                    <img src = {businesssvg} />
                    <p>Small to mid businesses</p>
                </div>

                <div className = 'card'>
                    <img src = {artistsvg} />
                    <p>Artists & digital artists</p>
                </div>

                <div className = 'card'>
                    <img src = {photographersvg} />
                    <p>Creative photographers</p>
                </div>

                <div className = 'card'>
                    <img src = {ccsvg} />
                    <p>Content creators</p>
                </div>

                <div className = 'card'>
                    <img src = {ytsvg} />
                    <p>YouTube creators</p>
                </div>

                <div className = 'card'>
                    <img src = {cpsvg} />
                    <p>Creative professionals</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ImakeWebsiteSection