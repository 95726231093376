import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Hamburger from '../icons/Hamburger-menu-Nilesh-Bhatia.svg';
import Logo from '../images/Nilesh-Bhatia-logo.png';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Contact from './pages/Contact';

export default function Header() {
    const [showMediaIcons, setShowMediaIcons] = useState(false);

    const handleLinkClick = () => {
        this.setState({ useState: false });
    };

    return (
        <>
            <nav className = 'header'>
                <div className = 'header-wrapper'>
                    <Link to = '/' className = 'logo'>
                        <img src = {Logo} /> 
                    </Link>

                    <ul className = {showMediaIcons ? 'nav-menu nav-menu-active' : 'nav-menu'}>
                        <li className = 'nav-item'>
                            <Link to = '/' className = 'nav-links' tabIndex = '-1' onClick = { handleLinkClick }>Home</Link>
                        </li>
                        <li className = 'nav-item'>
                            <Link to = 'About' className = 'nav-links' tabIndex = '-1' onClick = { handleLinkClick }>About</Link>
                        </li>
                        <li className = 'nav-item'>
                            <Link to = 'Portfolio' className = 'nav-links' tabIndex = '-1' onClick = { handleLinkClick }>Portfolio</Link>
                        </li>
                        <li className = 'nav-item'>
                            <Link to = 'Contact' className = 'nav-links' tabIndex = '-1' onClick = { handleLinkClick }>Contact</Link>
                        </li>
                    </ul>

                    <div className = 'hamburger' onClick = {() => setShowMediaIcons(!showMediaIcons)}>
                        <img src = {Hamburger}/>
                    </div>
                </div>
            </nav>               
        </>
    )
}