import React from 'react'
import './AboutSection.css'

function AboutSection() {
  return (
    <div className = 'aboutSection'>
        <div className = 'wrapper'>
            <div className = 'heading-block'>
                <h3>My name is Nilesh</h3>
                <h2>About</h2>
            </div>

            <div className = 'grid-block'>
                <div className = 'first-block'>
                    <p>Professionally, I'm</p>
                    <ul>
                        <li>a web designer</li>
                        <li>a freelancer</li>
                    </ul>
                    <a href = 'About'><button>Tell me more</button></a>
                </div>

                <div className = 'second-block'>
                    <p>Unprofessionally, I'm</p>
                    <ul>
                        <li>a poet</li>
                        <li>a writer</li>
                        <li>a listener</li>
                        <li>a wanderer</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutSection