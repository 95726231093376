import React from 'react'
import '../../App.css'
import './Portfolio.css'
import MajorNeelPic from '../../images/Major-Neel.webp'
import DAD from '../../images/DAD.png'
import Divider from '../../images/Nilesh-Bhatia-divider.png'

function Portfolio() {
  return (
    <div className = 'portfolio'>
        <div className = 'wrapper'>
            <h1>Portfolio</h1>

            <div className = 'name-container'>
                <p><span className = 'name'>Name:</span> <span className = 'content'>The Skin Doctor</span></p>
            </div>

            <div className = 'desc-container'>
                <p>
                    <span className = 'desc'>Description:</span> <span className = 'content'>Major Neel, widely known as The Skin Doctor, is a prominent figure on Twitter and YouTube. He is known for his satirical tweets and socio-political videos on YouTube. He wanted to have a personal website to showcase his personal and professional life. I'm blessed to get the opportunity to work with him.</span>
                </p>
            </div>

            <div className = 'img-container'>
                <img src = {MajorNeelPic} />
                <div className = 'text-container'>
                    <p>The Skin Doctor</p>
                    <p><a href = 'https://www.majorneel.in' target = '_blank'>Visit website</a></p>
                </div>
            </div>

            <img src = {Divider} class = 'divider'/>

            <div className = 'name-container'>
                <p><span className = 'name'>Name:</span> <span className = 'content'>Dial And Dial Solutions Private Limited</span></p>
            </div>

            <hr/>

            <div className = 'desc-container'>
                <p>
                    <span className = 'desc'>Description:</span> <span className = 'content'>Dial And Dial Solutions Private Limited is a B2B service based company which handle BPO projects and manpower recruitment services for various big names in the industry.</span>
                </p>
            </div>

            <div className = 'img-container img-container-2'>
                <img src = {DAD} />
                <div className = 'text-container'>
                    <p>DAD Solutions</p>
                    <p><a href = 'https://www.dad-solutions.com' target = '_blank'>Visit website</a></p>
                </div>
            </div>

            <p className = 'last-p'>More Projects coming.</p>
        </div>
    </div>
  )
}

export default Portfolio